'use strict';

angular.module('common')
	.factory('spinnerInterceptor', function($q) {
		var $uibModal = $("#spinnerOverlay");

		var pendingRequests = 0;

		return {
			request: function(config) {
				pendingRequests++;
				$uibModal.addClass("in");
				return config;
			},
			response: function(response) {
				if (--pendingRequests === 0) {
					$uibModal.removeClass("in");
				}
				return response;
			},
			responseError: function(response) {
				if (--pendingRequests === 0) {
					$uibModal.removeClass("in");
				}
				return $q.reject(response);
			}
		};
	})
	.config([ "$httpProvider", function($httpProvider) {
		$httpProvider.interceptors.push('spinnerInterceptor');
	}]);
